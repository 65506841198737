import request from '@/utils/request'

/**
 * get
 * 获取教师课程列表
 */
export const teacherPlan = (page, clerk_name, subject_id, period_id) => {
    return request({
        method: 'get',
        url: 'course/teacher-plan?page=' + page + '&clerk_name=' + clerk_name + '&subject_id=' + subject_id + '&period_id=' + period_id,
    })
}

/**
 * get
 * 获取教师课程表详情
 */
export const teacherPlanDetail = (id, check_time, type) => {
    return request({
      method: 'get',
      url: `course/${type ? 'student' : 'teacher'}-plan-detail?third_id=${id}&check_time=${check_time}`
    })
}
/**
 * get
 * 获取一级科目
 */
export const fistSubject = () => {
    return request({
        method: 'get',
        url: 'site/fist-subject',
    })
}

/**
 * get
 * 获取二级科目
 */
export const subject = (id) => {
    return request({
        method: 'get',
        url: 'site/subject?pid=' + id,
    })
}
// 及联选择科目获取
export const subjectArr = () => {
    return request({
        method: 'get',
        url: 'site/subject',
    })
}
